/* ***************************** */
/* HEADER STYLES */
/* ***************************** */
.full-app-container {
  position: relative;
}

.disclaimer-modal {
  position: fixed;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);
  z-index: 110;

  border: 1px solid #5ce1e6;
  border-radius: 9px;
  background-color: #fff;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
  padding: 2.4rem 2.4rem 4.8rem;
}

.disclaimer-modal-hidden {
  display: none;
}

.disclaimer-modal-close-btn {
  font-weight: 700;
  background-color: transparent;
  border-style: none;
  cursor: pointer;

  align-self: flex-end;
}

.disclaimer-modal-close-btn-icon {
  max-height: 2rem;
}

.disclaimer-modal-text {
  font-size: 1.8rem;
  text-align: center;
}

.app-outlet-container {
  min-height: 95vh;
  margin-top: 9.6rem;
}

.app-header {
  position: fixed;
  top: 0;
  z-index: 10;

  height: 9.6rem;

  display: flex;
  width: 100vw;
  gap: 1.2rem;
  padding: 0 3.2rem;
  align-items: center;
  justify-content: space-between;
  background-color: #fff;
}

.header *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem hsla(5, 85%, 97%, 0.5);
}

.logo-lockup-link {
  text-decoration: none;
}

.logo-lockup-link:focus {
  outline: none;
  box-shadow: none;
}

.nav-logo {
  position: absolute;
  top: 50%;
  transform: translateY(-52%);
  height: 15rem;
}

.nav-header {
  font-size: 3.6rem;
  color: #004aad;
  cursor: pointer;
}

.nav-menu-btn {
  border: none;
  background: transparent;
  cursor: pointer;
}

.nav-menu-btn-icon {
  max-width: 3.6rem;
}

/* ***************** */
/* Hidden Nav Window */
/* ***************** */

.hidden-nav {
  position: fixed;
  background-color: #fff;
  /* this top value should be equal to the
  height of the header component */
  top: 0;
  right: -60%;
  max-width: 70%;
  padding-bottom: 1.2rem;
  z-index: 1000;

  display: flex;
  flex-direction: column;

  border-radius: 18px 0 0 18px;
  border: 1px solid #004aad;
  border-style: none none solid solid;

  transition: all 0.8s;
}

.nav-open {
  right: 0;
}

.hidden-nav-close-btn {
  position: absolute;
  top: 3%;
  left: 3%;

  font-weight: 700;
  max-height: 2rem;
  background-color: transparent;
  border-style: none;
  cursor: pointer;
}

.hidden-nav-close-btn-icon {
  max-height: 2rem;
}

.nav-user-welcome-container {
  display: flex;
  align-items: center;
  justify-content: flex-end;
  gap: 1.2rem;

  padding: 2.4rem 2.4rem 0 0;
}

.nav-user-welcome-message {
  font-size: 1.8rem;
}

.nav-user-welcome-icon {
  max-width: 4.4rem;
  max-height: 4.4rem;
  border: 1px solid #004aad;
  border-radius: 50%;
}

.nav-subhead {
  font-size: 1.8rem;
  font-weight: 500;
}

.nav-all-sections-container {
  padding: 2.4rem 2.4rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.nav-section-container {
  display: flex;
  flex-direction: column;
  align-items: flex-end;
  gap: 1.2rem;
}

.nav-section-links-container {
  display: flex;
  gap: 1.2rem;
}

.nav-link:link,
.nav-link:visited {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  border: 1px solid #004aad;
  border-radius: 9px;
  max-width: 20rem;
  height: 7rem;

  text-decoration: none;
  color: #004aad;

  padding: 0.8rem 1.6rem;
  transition: all 0.4s;
}

.nav-link:hover,
.nav-link:active {
  background-color: #004aad;
  color: #fff;
}

.nav-link-title {
  font-size: 1.4rem;
  font-weight: 700;
}

.nav-link-description {
  font-size: 1.2rem;
  font-weight: 500;
}

.nav-text-link:link,
.nav-text-link:visited {
  font-size: 2rem;
  font-weight: 700;
  text-decoration: none;
  color: #004aad;
  transition: all 0.4s;

  padding: 1.2rem 0;
}

.nav-text-link:hover,
.nav-text-link:active {
  color: #5ce1e6;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .hidden-nav {
    right: -70%;
  }

  .nav-open {
    right: 0;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .disclaimer-modal {
    width: 60%;
  }

  .disclaimer-modal-close-btn-icon {
    max-height: 1.6rem;
  }

  .disclaimer-modal-text {
    font-size: 1.6rem;
  }

  .app-header {
    padding: 0 2.4rem;
    top: 0;
    max-height: 8rem;
  }

  .nav-logo {
    height: 14rem;
  }

  .nav-header {
    font-size: 3rem;
  }

  .nav-menu-btn-icon {
    max-width: 3rem;
  }

  /* margin top for app-outlet-container must be equal
  to the app-header height */
  .app-outlet-container {
    margin-top: 6.4rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .nav-section-links-container {
    display: flex;
    flex-direction: column;
    gap: 1.2rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .disclaimer-modal {
    width: 80%;
  }

  .app-header {
    padding: 0 1.6rem;
  }

  .nav-logo {
    height: 12rem;
  }

  .nav-header {
    font-size: 2.4rem;
  }

  .nav-menu-btn-icon {
    max-width: 3.6rem;
  }

  .nav-user-welcome-message {
    font-size: 1.6rem;
  }

  .nav-subhead {
    font-size: 1.4rem;
  }

  .nav-link-title {
    font-size: 1.2rem;
  }

  .nav-link-description {
    font-size: 1rem;
  }

  .nav-text-link:link,
  .nav-text-link:visited {
    font-size: 1.8rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .app-header {
    max-height: 7rem;
  }

  .nav-logo {
    max-height: 9rem;
  }

  .nav-header {
    font-size: 1.8rem;
  }

  .nav-menu-btn-icon {
    max-width: 3rem;
  }

  .app-outlet-container {
    margin-top: 4.8rem;
  }
}
