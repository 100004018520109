.contact-us-section {
  background-color: #fff;
  height: 100vh;
  display: flex;
  align-items: center;
}

.contact-us-container {
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 2.4rem 4.8rem;
}
