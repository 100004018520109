/* ***************************** */
/* HERO SECTION STYLES */
/* ***************************** */

.section-hero {
  min-height: 95vh;
  padding-top: 9.6rem;
  background-color: #fff;
}

.hero {
  max-width: 130rem;
  margin: 0 auto;
  padding: 0 2.4rem;
}

.hero-flex-container {
  display: flex;
  align-items: flex-start;
  justify-content: center;
  gap: 8rem;

  margin: 0 auto;
  max-width: 80%;
}

.hero-text-box {
  max-width: 60rem;
}

.hero-heading-primary {
  font-size: 3.6rem;
  font-weight: 700;
  line-height: 1.1;
  color: #004aad;
  letter-spacing: -0.3px;
  margin-bottom: 1.6rem;
}

.hero-description {
  font-size: 1.8rem;
  line-height: 1.5;
  margin-bottom: 3.2rem;
}

.hero-mvp-disclaimer {
  margin-top: 1.6rem;
  max-width: 80%;
  font-size: 1.6rem;
  color: maroon;
}

.hero-search {
  display: inline-block;

  width: 60%;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  color: #004aad;
  font-size: 1.6rem;
  font-family: inherit;
}

.hero-search::placeholder {
  font-family: inherit;
  font-style: italic;
  color: #555;
}

.hero-img {
  max-width: 50rem;
  border-radius: 9px;
}

.search-results-preview-position {
  top: 85%;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
  .hero-flex-container {
    gap: 6.4rem;
  }

  .hero-heading-primary {
    font-size: 3.2rem;
  }

  .hero-description {
    font-size: 1.8rem;
    margin-bottom: 3.2rem;
  }

  .hero-mvp-disclaimer {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  .hero-flex-container {
    align-items: center;
    gap: 6.4rem;
  }

  .hero-search {
    font-size: 1.4rem;
  }

  .hero-img {
    max-width: 45rem;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .hero-flex-container {
    max-width: 85%;
  }

  .hero-heading-primary {
    font-size: 3rem;
  }

  .hero-description {
    font-size: 1.4rem;
  }

  .hero-mvp-disclaimer {
    font-size: 1.2rem;
  }

  .hero-search {
    font-size: 1.2rem;
  }

  .hero-img {
    max-width: 37rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .hero-heading-primary {
    font-size: 2.4rem;
  }

  .hero-description {
    font-size: 1.2rem;
  }

  .hero-img {
    max-width: 30rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .section-hero {
    padding-top: 4.8rem;
  }

  .hero-flex-container {
    flex-direction: column-reverse;
    gap: 2rem;
  }

  .hero-img-box {
    align-self: flex-start;
  }

  .hero-img {
    max-width: 35rem;
  }

  .hero-search {
    width: 65%;
    font-size: 1rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .hero-flex-container {
    max-width: 80%;
  }

  .hero-img {
    width: 25rem;
  }

  .hero-heading-primary {
    font-size: 2rem;
  }
}
