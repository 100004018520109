/* ***************************** */
/* RESULT DATA PAGE STYLE */
/* ***************************** */

.result-data-page-container {
  padding: 4.8rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;
}

/* .result-data-page-treatment-visualized-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.result-data-page-treatment-visualized-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
} */

.result-data-page-title {
  font-size: 3.6rem;
  font-weight: 700;
  color: #004aad;
  text-align: center;
}

.result-data-page-peer-insights-title-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;
}

.result-data-page-subhead {
  font-size: 2.4rem;
  font-weight: 600;
  text-align: center;
}

.result-data-page-subhead-description {
  font-size: 1.4rem;
  font-style: italic;
  text-align: center;
}

/* .result-data-page-survey-link-btn:link,
.result-data-page-survey-link-btn:visited {
  margin: 1.2rem 0;
  padding: 0.8rem 1.6rem;
  border: 1px solid #004aad;
  border-radius: 9px;

  font-size: 1.2rem;
  text-decoration: none;
  color: #004aad;

  transition: all 0.4s;
}

.result-data-page-survey-link-btn:hover,
.result-data-page-survey-link-btn:active {
  background-color: #004aad;
  color: #fff;
} */

.result-data-page-horizontal-line {
  width: 20vw;
  height: 0;
  border: 0.5px solid #555;
  margin-bottom: 1.2rem;
}

.result-data-page-treatment-visualizations-container {
  font-size: 3rem;
  text-align: center;
}

.result-data-page-peer-insights-section-container {
  margin-top: 1.6rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.result-data-page-search-filter-flags-container {
  display: flex;
  flex-direction: column;
  align-items: center;

  gap: 3.2rem;
}

.result-data-page-search-and-filter-container {
  display: flex;
  align-items: flex-end;
  gap: 1.2rem;
}

.results-data-page-insights-search-bar-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;
}

.result-data-page-insights-search-bar-description {
  font-size: 1.2rem;
  font-style: italic;
}

.results-data-page-insights-search-bar {
  width: 30rem;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  color: #004aad;
  font-size: 1.2rem;
  font-family: inherit;
}

.results-data-page-filter-insights-btn {
  background-color: #5ce1e6;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 1.2rem;

  font-size: 1.2rem;
  font-family: inherit;
  font-weight: 600;
  color: black;
  cursor: pointer;
}

.result-data-page-peer-insights-container {
  column-gap: 4.8rem;
  row-gap: 9.6rem;
  min-height: 60rem;
  width: 110rem;

  margin-top: 6.4rem;
}

.all-insights-no-matching-insights-reminder {
  grid-column: 1/-1;
  text-align: center;
  font-size: 2rem;
  color: #555;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  .result-data-page-peer-insights-container {
    width: 100rem;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .result-data-page-title {
    font-size: 3rem;
  }

  .result-data-page-subhead {
    font-size: 2rem;
  }

  .result-data-page-subhead-description {
    font-size: 1.2rem;
  }

  .result-data-page-peer-insights-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    gap: 9.6rem;
    width: 70rem;
  }

  .all-insights-no-matching-insights-reminder {
    font-size: 1.8rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .result-data-page-treatment-visualized-container {
    padding: 0 4.8rem;
  }

  .result-data-page-peer-insights-title-container {
    padding: 0 4.8rem;
  }

  .results-data-page-insights-search-bar {
    width: 27rem;
    font-size: 1rem;
  }

  .results-data-page-filter-insights-btn {
    padding: 1.2rem 0.8rem;
    font-size: 1rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .all-insights-no-matching-insights-reminder {
    font-size: 1.6rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .result-data-page-title {
    font-size: 2.4rem;
  }

  .result-data-page-subhead {
    font-size: 1.8rem;
  }

  .result-data-page-subhead-description {
    font-size: 1rem;
  }

  .result-data-page-treatment-visualizations-container {
    font-size: 2rem;
  }

  .result-data-page-insights-search-bar-description {
    font-size: 1rem;
  }

  .results-data-page-insights-search-bar {
    width: 20rem;
  }

  .all-insights-no-matching-insights-reminder {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .result-data-page-search-and-filter-container {
    gap: 0.4rem;
  }

  .result-data-page-insights-search-bar-description {
    font-size: 0.9rem;
  }

  .results-data-page-insights-search-bar {
    width: 18rem;
    font-size: 0.8rem;
  }

  .results-data-page-filter-insights-btn {
    padding: 1.2rem 0.4rem;
    font-size: 0.8rem;
  }
}
