/* *****************************
/* FOOTER STYLE */
/* ***************************** */

.footer {
  background-color: #004aad;
  padding: 2.4rem 2.4rem;

  display: flex;
  justify-content: space-between;
  align-items: center;

  width: 100vw;
}

/* .footer-social-links {
  cursor: pointer;

  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.footer-social-icon {
  fill: #fff;
  max-width: 4.4rem;
  transition: all 0.3s;
}

.footer-social-icon:hover {
  fill: #5ce1e6;
}

.footer-other-links {
  font-size: 1.4rem;

  display: flex;
  align-items: center;
  gap: 2.4rem;
}

.footer-link:link,
.footer-link:visited {
  color: #fff;
  text-decoration: none;
  padding: 0.2rem;

  transition: all 0.3s;
}

.footer-link:hover,
.footer-link:active {
  color: #5ce1e6;
} */

.footer-support-email:link,
.footer-support-email:visited {
  color: #fff;
  text-decoration: none;
  font-size: 1.8rem;
  transition: all 0.4s;
}

.footer-support-email:hover,
.footer-support-email:active {
  color: #5ce1e6;
}

.footer-copyright {
  color: #fff;
  font-size: 1.8rem;
}

.footer *:focus {
  outline: none;
  box-shadow: 0 0 0 0.8rem hsla(5, 85%, 97%, 0.5);
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .footer {
    padding: 1.6rem 2.4rem;
  }

  .footer-support-email:link,
  .footer-support-email:visited {
    font-size: 1.4rem;
  }

  .footer-copyright {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .footer-support-email:link,
  .footer-support-email:visited {
    font-size: 1.2rem;
  }

  .footer-copyright {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .footer {
    padding: 1.2rem 1.6rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .footer-support-email:link,
  .footer-support-email:visited {
    font-size: 1rem;
  }

  .footer-copyright {
    font-size: 0.9rem;
  }
}
