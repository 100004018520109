.insights-filter-flags-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  width: 90%;
}

.insights-filter-flags-header {
  font-size: 2rem;
  font-weight: 700;
  color: #004aad;
}

.insights-filter-flags-all-filters-container {
  gap: 1.2rem;
}

.insights-filter-flags-filter-box {
  min-height: 4.8rem;
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 0.8rem;

  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 0.8rem 1.6rem;
  background-color: #fff;
}

.search-term-flag {
}

.insight-category-flag {
}

.drug-or-disease-id-flag {
}

.clinician-type-flag {
}

.insights-filter-flags-filter-name {
  font-size: 1.4rem;
  font-weight: 500;
  color: #555;
}

.flag-highlight {
  font-weight: 700;
  color: #004aad;
  transition: all 0.4s;
}

.insights-filter-flags-remove-filter-btn {
  background-color: transparent;
  border: none;
  cursor: pointer;
  font-size: 0;
}

.insights-filter-flags-remove-filter-btn-icon {
  width: 1rem;
}

.insights-filter-flags-clinican-types-filter-warning {
  color: maroon;
  font-size: 1.4rem;
  transition: all 0.4s;
}

.filter-warning-transparent {
  color: transparent;
  transition: all 0.4s;
}

.insights-filter-flags-add-all-clinicians-button {
  cursor: pointer;
  transition: all 0.4s;
  border: none;
}

.all-clinicians-btn-box {
  border: 1px solid maroon;
  transition: all 0.4s;
}

.all-clinicians-btn-box:hover .flag-highlight,
.all-clinicians-btn-box:active .flag-highlight {
  color: #fff;
}

.all-clinicians-btn-box:hover,
.all-clinicians-btn-box:active {
  border: 1px solid #004aad;
  background-color: #004aad;
}

.insights-filter-flags-add-all-clinicians-button .flag-highlight {
  color: maroon;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .insights-filter-flags-header {
    font-size: 1.8rem;
  }

  .insights-filter-flags-all-filters-container {
    grid-template-columns: repeat(2, 1fr);
  }

  .insights-filter-flags-filter-box {
    padding: 0.8rem 1.2rem;
    gap: 0.4rem;
  }

  .insights-filter-flags-filter-name {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .insights-filter-flags-header {
    font-size: 1.6rem;
  }

  .insights-filter-flags-filter-box {
    padding: 0.4rem 0.8rem;
    gap: 0.2rem;
    max-width: 15rem;
  }

  .insights-filter-flags-filter-name {
    font-size: 1rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .insights-filter-flags-filter-box {
    max-width: 13rem;
  }

  .insights-filter-flags-filter-name {
    font-size: 1rem;
  }
}
