.data-insight-details-modal-container {
  position: fixed;
  width: 70%;
  height: 70%;
  padding: 1.6rem 3.2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  border-radius: 9px;
  border: 1px solid #004aad;
  background-color: #fff;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;

  overflow: scroll;
  transition: all 0.4s;
}

.modal-hidden {
  display: none;
}

.data-insight-details-modal-close-btn {
  position: absolute;
  right: 1.5%;
  top: 1.5%;
  margin: 0.4rem 0.4rem 0 0;
  background-color: transparent;
  border: none;
  cursor: pointer;
}

.data-insight-details-modal-close-btn-icon {
  width: 2rem;
}

.data-insight-details-modal-ratings-container {
  display: flex;
  gap: 1.2rem;
  align-self: flex-start;
}

.data-insight-details-modal-ratings-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  background-color: transparent;
  border: none;

  cursor: pointer;
  transition: all 0.4s;
}

.details-modal-clicked-ratings-btn {
  transform: translateY(-30%);
}

.data-insight-details-modal-ratings-icon {
  width: 2.4rem;
}

.data-insight-details-modal-ratings-label {
  font-size: 1.2rem;
  color: #555;
  transition: all 0.3s;
}

.details-modal-clicked-label {
  color: red;
  font-weight: 600;
}

.data-insight-details-modal-title {
  color: #004aad;
  font-size: 3rem;
  margin: 1.6rem 0;
  text-align: center;
}

.data-insight-details-modal-summary {
  text-align: center;
  font-size: 1.8rem;
  color: #555;
}

.data-insight-details-modal-summary-top-items {
  font-weight: 600;
  color: #004aad;
}

.data-insight-details-modal-horizontal-line {
  width: 50%;
  border: 1px solid #555;
}

.data-insight-details-modal-data-container {
  width: 95%;
}

.data-insight-details-modal-data-description {
  font-size: 1.6rem;
  text-align: center;
  color: #555;
}

.data-insight-details-modal-data-description-respondent-count-highlight {
  font-weight: 600;
}

.data-insight-details-modal-data-visual-container {
  margin-top: 3.2rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 0.8rem;
}

.data-insight-details-modal-data-visual-row-circles {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.data-insight-details-modal-data-label-circles:link,
.data-insight-details-modal-data-label-circles:visited {
  width: 30rem;
  display: flex;
  align-items: center;

  font-size: 1.8rem;
  text-decoration: none;
  color: #004aad;

  transition: all 0.4s;
}

.data-insight-details-modal-data-label-circles:hover,
.data-insight-details-modal-data-label-circles:active {
  color: #5ce1e6;
}

.data-insight-details-modal-data-visualization-circles {
  display: flex;
  gap: 0.1rem;
}

.data-insight-details-modal-data-visualization-circle-full {
  height: 2.4rem;
  width: 2.4rem;
  border: 1px solid #004aad;
  background-color: #004aad;
  border-radius: 50%;
}

.data-insight-details-modal-data-visualization-circle-empty {
  height: 2.4rem;
  width: 2.4rem;
  border: 1px solid #004aad;
  background-color: #fff;
  border-radius: 50%;
}

.data-insight-details-modal-data-visualization-number-label {
  width: 6rem;
  display: flex;
  align-items: center;

  font-size: 1.6rem;
  color: #555;

  transition: all 0.3s;
}

.data-insight-details-modal-data-number {
  align-self: center;
  justify-self: center;

  font-size: 3.6rem;
  font-weight: 600;
  color: #004aad;
}

.data-insight-details-modal-subhead {
  text-align: center;
  color: #004aad;
  font-size: 2rem;
  margin: 2.4rem 0 1.2rem;
}

.data-insight-details-modal-survey-question-container {
  padding: 0 3.2rem;
}

.data-insight-details-modal-survey-intro {
  text-align: center;
  font-size: 1.6rem;
}

.data-insight-details-modal-survey-question {
  margin: 1.2rem 0;
  text-align: left;
  font-size: 1.8rem;
  font-style: italic;
}

.data-insight-details-modal-rationale-container {
  display: flex;
  flex-direction: column;
  width: 100%;
}

.data-insight-details-modal-rationale-drop-down-menu {
  margin: 1.2rem 0 2.4rem;
  align-self: center;
  width: 50%;
  padding: 0.4rem 0.8rem;
  border-radius: 9px;
  font-size: 1.2rem;
  color: #555;
}

.data-insights-details-modal-rationale-list-container {
  list-style: none;
  align-self: center;
  width: 90%;
}

.data-insights-details-modal-rationale-list-item {
  margin-bottom: 1.2rem;
}

.data-insights-details-modal-rationale-item-container {
  border: 1px solid #004aad;
  border-radius: 9px;

  display: flex;
  flex-direction: column;
  gap: 1.6rem;
  padding: 0.8rem 1.2rem 2.4rem;
}

.data-insights-details-modal-rationale-intro-container {
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.data-insights-details-modal-rationale-clinician-profile-container {
  width: 100%;
  display: flex;
  align-items: center;
  gap: 0.8rem;
}

.data-insights-detail-modal-rationale-clinician-profile-pic {
  border: 1px solid #004aad;
  border-radius: 50%;
  max-width: 5.2rem;
  max-height: 5.2rem;
}

.data-insights-detail-modal-rationale-clinician-name-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.data-insights-detail-modal-rationale-clinician-name {
  font-size: 1.6rem;
  font-weight: 600;
}

.data-insights-detail-modal-rationale-clinician-specialty {
  font-size: 1.2rem;
}

.data-insights-detail-modal-rationale-clinician-institution {
  font-size: 1.2rem;
}

.data-insight-details-modal-rationale-ratings-container {
  align-self: flex-start;
  margin-top: 0.4rem;

  display: flex;
  gap: 1.2rem;
}

.data-insight-details-modal-rationale-ratings-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  background-color: transparent;
  border: none;

  cursor: pointer;
  transition: all 0.4s;
}

.details-modal-rationale-clicked-ratings-btn {
  transform: translateY(-30%);
}

.data-insight-details-modal-rationale-ratings-icon {
  width: 1.8rem;
}

.data-insight-details-modal-rationale-ratings-label {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  transition: all 0.3s;
  white-space: nowrap;
}

.details-modal-rationale-clicked-label {
  color: red;
  font-weight: 600;
}

.data-insights-details-modal-rationale-selection-and-text-container {
  padding: 0.8rem 1.6rem;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.data-insights-details-modal-rationale-selection {
  font-size: 1.8rem;
  color: #004aad;
}

.rationale-selection-highlight {
  font-weight: 600;
}

.data-insights-details-modal-rationale-brief-intro {
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.data-insights-details-modal-rationale-brief-text {
  font-size: 1.6rem;
  font-style: italic;
  color: #004aad;
}

.data-insights-details-modal-rationale-full-intro {
  margin-top: 1.2rem;
  font-size: 1.8rem;
  font-weight: 500;
}

.data-insights-details-modal-rationale-full-text {
  font-size: 1.6rem;
  font-style: italic;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .data-insight-details-modal-container {
    padding: 1.6rem 1.6rem;
  }

  .data-insight-details-modal-ratings-icon {
    width: 2rem;
  }

  .data-insight-details-modal-title {
    font-size: 2.4rem;
  }

  .data-insight-details-modal-summary {
    font-size: 1.6rem;
  }

  .data-insight-details-modal-data-description {
    font-size: 1.4rem;
  }

  .data-insight-details-modal-data-label-circles:link,
  .data-insight-details-modal-data-label-circles:visited {
    font-size: 1.6rem;
  }

  .data-insight-details-modal-data-visualization-circle-full {
    height: 2rem;
    width: 2rem;
  }

  .data-insight-details-modal-data-visualization-number-label {
    width: 5rem;
    font-size: 1.4rem;
  }

  .data-insight-details-modal-data-visualization-circle-empty {
    height: 2rem;
    width: 2rem;
  }

  .data-insight-details-modal-subhead {
    font-size: 1.8rem;
  }

  .data-insight-details-modal-survey-intro {
    font-size: 1.4rem;
  }

  .data-insight-details-modal-survey-question {
    font-size: 1.6rem;
  }

  .data-insights-details-modal-rationale-item-container {
    padding: 1.2rem 1.6rem 2.4rem;
  }

  .data-insights-detail-modal-rationale-clinician-profile-pic {
    max-width: 4.4rem;
    max-height: 4.4rem;
  }

  .data-insights-detail-modal-rationale-clinician-name {
    font-size: 1.4rem;
  }

  .data-insights-detail-modal-rationale-clinician-specialty {
    font-size: 1rem;
  }

  .data-insights-detail-modal-rationale-clinician-institution {
    font-size: 1rem;
  }

  .data-insight-details-modal-rationale-ratings-icon {
    width: 1.6rem;
  }

  .data-insights-details-modal-rationale-selection {
    font-size: 1.6rem;
  }

  .data-insights-details-modal-rationale-brief-intro {
    font-size: 1.6rem;
  }

  .data-insights-details-modal-rationale-brief-text {
    font-size: 1.4rem;
  }

  .data-insights-details-modal-rationale-full-intro {
    font-size: 1.6rem;
  }

  .data-insights-details-modal-rationale-full-text {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .data-insight-details-modal-close-btn-icon {
    width: 1.8rem;
  }

  .data-insight-details-modal-ratings-icon {
    width: 1.8rem;
  }

  .data-insight-details-modal-ratings-label {
    font-size: 1rem;
  }

  .data-insight-details-modal-title {
    font-size: 2rem;
  }

  .data-insight-details-modal-summary {
    font-size: 1.4rem;
  }

  .data-insight-details-modal-data-description {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-data-label-circles:link,
  .data-insight-details-modal-data-label-circles:visited {
    width: 19rem;
    font-size: 1.4rem;
  }

  .data-insight-details-modal-data-visualization-circle-full {
    height: 1.8rem;
    width: 1.8rem;
  }

  .data-insight-details-modal-data-visualization-circle-empty {
    height: 1.8rem;
    width: 1.8rem;
  }

  .data-insight-details-modal-data-visualization-number-label {
    width: 4rem;
    font-size: 1.2rem;
  }

  .data-insight-details-modal-subhead {
    font-size: 1.6rem;
    margin: 1.2rem 0;
  }

  .data-insight-details-modal-survey-intro {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-survey-question {
    font-size: 1.4rem;
  }

  .data-insight-details-modal-rationale-drop-down-menu {
    margin: 1.2rem 0 2.4rem;
    width: 50%;
    font-size: 1rem;
  }

  .data-insights-details-modal-rationale-item-container {
    padding: 1.2rem 1.6rem;
  }

  .data-insights-detail-modal-rationale-clinician-profile-pic {
    max-width: 3.6rem;
    max-height: 3.6rem;
  }

  .data-insights-detail-modal-rationale-clinician-name {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-rationale-ratings-icon {
    width: 1.4rem;
  }

  .data-insight-details-modal-rationale-ratings-label {
    font-size: 0.8rem;
  }

  .data-insights-details-modal-rationale-selection {
    font-size: 1.4rem;
  }

  .data-insights-details-modal-rationale-brief-intro {
    font-size: 1.4rem;
  }

  .data-insights-details-modal-rationale-brief-text {
    font-size: 1.2rem;
  }

  .data-insights-details-modal-rationale-full-intro {
    font-size: 1.4rem;
  }

  .data-insights-details-modal-rationale-full-text {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .data-insight-details-modal-container {
    width: 80%;
    gap: 1.4rem;
  }

  .data-insight-details-modal-data-label-circles:link,
  .data-insight-details-modal-data-label-circles:visited {
    width: 18rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .data-insight-details-modal-container {
    width: 85%;
    padding: 1.6rem 1.2rem;
  }

  .data-insight-details-modal-close-btn-icon {
    width: 1.6rem;
  }

  .data-insight-details-modal-ratings-container {
    gap: 0.8rem;
  }

  .data-insight-details-modal-ratings-icon {
    width: 1.6rem;
  }

  .data-insight-details-modal-ratings-label {
    font-size: 0.9rem;
  }

  .data-insight-details-modal-title {
    font-size: 1.8rem;
  }

  .data-insight-details-modal-summary {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-data-description {
    font-size: 1.1rem;
  }

  .data-insight-details-modal-data-label-circles:link,
  .data-insight-details-modal-data-label-circles:visited {
    width: 15rem;
    font-size: 1.2rem;
  }

  .data-insight-details-modal-data-visualization-circle-full {
    height: 1.6rem;
    width: 1.6rem;
  }

  .data-insight-details-modal-data-visualization-circle-empty {
    height: 1.6rem;
    width: 1.6rem;
  }

  .data-insight-details-modal-data-visualization-number-label {
    width: 3rem;
    font-size: 0.9rem;
  }

  .data-insight-details-modal-subhead {
    font-size: 1.4rem;
  }

  .data-insight-details-modal-survey-intro {
    font-size: 1.1rem;
  }

  .data-insight-details-modal-survey-question {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-rationale-drop-down-menu {
    margin: 0.8rem 0 2.4rem;
  }

  .data-insights-details-modal-rationale-item-container {
    padding: 1.2rem 1.2rem;
  }

  .data-insights-detail-modal-rationale-clinician-profile-pic {
    max-width: 3rem;
    max-height: 3rem;
  }

  .data-insights-detail-modal-rationale-clinician-name {
    font-size: 1rem;
  }

  .data-insight-details-modal-rationale-ratings-container {
    margin-top: 0;
    gap: 0.8rem;
  }

  .data-insight-details-modal-rationale-ratings-icon {
    width: 1.2rem;
  }

  .data-insights-details-modal-rationale-selection-and-text-container {
    padding: 0.8rem 0.4rem;
    gap: 0.8rem;
  }

  .data-insights-details-modal-rationale-selection {
    font-size: 1.2rem;
  }

  .data-insights-details-modal-rationale-brief-intro {
    font-size: 1.2rem;
  }

  .data-insights-details-modal-rationale-brief-text {
    font-size: 1.1rem;
  }

  .data-insights-details-modal-rationale-full-intro {
    font-size: 1.2rem;
  }

  .data-insights-details-modal-rationale-full-text {
    font-size: 1.1rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .data-insight-details-modal-container {
    padding: 1.2rem 1.2rem;
    width: 95%;
  }

  .data-insight-details-modal-close-btn-icon {
    width: 1.4rem;
  }

  .data-insight-details-modal-ratings-icon {
    width: 1.4rem;
  }

  .data-insight-details-modal-ratings-label {
    font-size: 0.8rem;
  }

  .data-insight-details-modal-title {
    font-size: 1.6rem;
  }

  .data-insight-details-modal-summary {
    font-size: 1.1rem;
  }

  .data-insight-details-modal-data-description {
    font-size: 1rem;
  }

  .data-insight-details-modal-data-label-circles:link,
  .data-insight-details-modal-data-label-circles:visited {
    width: 12rem;
    font-size: 1rem;
  }

  .data-insight-details-modal-data-visualization-circle-full {
    height: 1.4rem;
    width: 1.4rem;
  }

  .data-insight-details-modal-data-visualization-circle-empty {
    height: 1.4rem;
    width: 1.4rem;
  }

  .data-insight-details-modal-subhead {
    font-size: 1.2rem;
  }

  .data-insight-details-modal-survey-intro {
    font-size: 1rem;
  }

  .data-insight-details-modal-survey-question {
    font-size: 1.1rem;
  }

  .data-insights-detail-modal-rationale-clinician-profile-pic {
    max-width: 2.8rem;
    max-height: 2.8rem;
  }

  .data-insights-detail-modal-rationale-clinician-name {
    font-size: 0.9rem;
  }

  .data-insight-details-modal-rationale-ratings-container {
    gap: 0.6rem;
  }

  .data-insight-details-modal-rationale-ratings-btn {
    gap: 0.2rem;
  }

  .data-insight-details-modal-rationale-ratings-icon {
    width: 1rem;
  }

  .data-insight-details-modal-rationale-ratings-label {
    font-size: 0.7rem;
  }

  .data-insights-details-modal-rationale-selection-and-text-container {
    gap: 0.4rem;
  }

  .data-insights-details-modal-rationale-selection {
    font-size: 1rem;
  }

  .data-insights-details-modal-rationale-brief-intro {
    font-size: 1.1rem;
  }

  .data-insights-details-modal-rationale-brief-text {
    font-size: 1rem;
  }

  .data-insights-details-modal-rationale-full-intro {
    font-size: 1.1rem;
  }

  .data-insights-details-modal-rationale-full-text {
    font-size: 1rem;
  }
}
