/* 

--- 01 TYPOGRAPHY SYSTEM

- FONT STYLE - Quicksand
https://fonts.google.com/specimen/Quicksand?query=quicks

- FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

- Font weights: 
Default: 400
Med: 500
Med Bold: 600
Bold: 700

- Line heights:
Default: 1
1.1
1.5

-Letter spacing
-0.3px

--- 02 COLORS

- Primary: #004aad

- Tints: #def9fa, #336ebd (contrast good against #fff)

- Shades: #2e7173 (contrast good against #fff)

- Accents: #fff #5ce1e6,

- Greys: #555


--- 05 SHADOWS

--- 06 BORDER-RADIUS

Default: 9px

--- 07 WHITESPACE

- SPACING SYSTEM (px)
2 / 4 / 8 / 12 / 16 / 24 / 32 / 48 / 64 / 80 / 96 / 128

- FONT SIZE SYSTEM (px)
10 / 12 / 14 / 16 / 18 / 20 / 24 / 30 / 36 / 44 / 52 / 62 / 74 / 86 / 98

--- 08 OTHER STYLING

- TRANSITIONS
standard - all 0.4s

*/

* {
  margin: 0;
  padding: 0;
  box-sizing: border-box;
}

html {
  font-size: 62.5%;
  overflow-x: hidden;
}

body {
  font-family: "Quicksand", sans-serif;
  line-height: 1;
  font-weight: 400;
  color: #555;
  overflow-x: hidden;
}

.prevent-scroll {
  overflow: hidden;
  height: 100vh;
}

.App {
  position: relative;
}

/* GENERAL REUSABLE COMPONENTS */
.container {
  max-width: 120rem;
  margin: 0 auto;
  padding: 0 3.2rem;
}

.grid {
  display: grid;
  column-gap: 9.6rem;
}

.grid--2-cols {
  grid-template-columns: repeat(2, 1fr);
}

.grid--3-cols {
  grid-template-columns: repeat(3, 1fr);
}

.grid--4-cols {
  grid-template-columns: repeat(4, 1fr);
}

.grid--5-cols {
  grid-template-columns: repeat(5, 1fr);
}

.grid--1-row {
  grid-template-rows: 1fr;
}

.grid--center-v {
  align-items: center;
}

.grid--center-h {
  justify-content: center;
}

*:focus {
  outline: none;
  border-radius: 9px;
  box-shadow: 0 0 0 0.2rem rgba(0, 75, 173, 0.5);
}

/* Misc resusable classes */
.hidden {
  display: none;
}

.background-blur {
  position: fixed;
  /* display: none; */
  top: -10%;
  right: 0;
  background-color: rgba(222, 249, 250, 0.9);
  z-index: 100;

  height: 0;
  width: 0;
}

.blur-on {
  height: 150vh;
  width: 100vw;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  html {
    font-size: 56.25%;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  /* MOBILE Navigation */
}

/* *************************** */
/* BELOW 720px (smaller tablets) */
/* *************************** */

@media (max-width: 45em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
}
