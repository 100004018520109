/* ***************************** */
/* SEARCH RESULTS PAGE STYLE */
/* ***************************** */

.section-search {
  padding: 3.2rem 2.4rem 4.8rem;
  min-height: 90vh;
}

.search-container {
  margin-top: 4.8rem;
}

.search-bar-container {
  display: flex;
  gap: 1.2rem;
  align-items: center;
}

.search-results-search-input {
  display: inline-block;
  width: 30rem;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  color: #004aad;
  font-size: 1.6rem;
  font-family: inherit;
}

.search-bar-container .search-results-preview {
  width: 30rem;
}

.search-results-search-input::placeholder {
  font-family: inherit;
  font-style: italic;
  color: #555;
}

.search-results-preview-position {
  top: 110%;
}

.search-description {
  max-width: 65%;
  margin: 2.4rem 0 4.8rem;
  font-size: 2rem;
  font-style: italic;
  color: #004aad;
}

.search-resut-mvp-disclaimer {
  color: maroon;
  font-weight: 600;
}

.search-results-container {
  display: flex;
  flex-direction: column;
  gap: 4.8rem;
}

.search-result-error {
  font-size: 2rem;
  margin-bottom: 2.4rem;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .search-results-search-input {
    width: 27rem;
    font-size: 1.2rem;
  }

  .search-bar-container .search-results-preview {
    width: 27rem;
  }

  .search-description {
    font-size: 1.8rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .section-search {
    padding: 1.2rem 1.2rem 4.8rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .search-results-search-input {
    width: 23rem;
  }

  .search-bar-container .search-results-preview {
    width: 23rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .search-results-search-input {
    width: 20rem;
  }

  .search-bar-container .search-results-preview {
    width: 20rem;
  }

  .search-description {
    max-width: 90%;
    font-size: 1.6rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
}
