/* ***************************** */
/* SEARCH RESULT COMPONENT STYLE */
/* ***************************** */
.search-result-title:visited,
.search-result-title:link {
  display: inline-block;
  cursor: pointer;
  text-decoration: none;
  font-size: 2.4rem;
  font-weight: 600;
  color: #004aad;

  margin-bottom: 1.2rem;
  transition: all 0.4s;
}

.search-result-title:active,
.search-result-title:hover {
  color: #5ce1e6;
}

.search-result-details-container {
  padding: 0.8rem 0 0 2rem;
}

.search-result-trending-topics-subhead {
  font-size: 1.8rem;
  font-weight: 500;
  margin: 0 0 1.2rem;
}

.search-result-insight-names-container {
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.search-result-insight-name:link,
.search-result-insight-name:visited {
  font-size: 1.6rem;
  font-style: italic;
  color: #004aad;
  text-decoration: none;
  transition: 0.4s all;
}

.search-result-insight-name:hover,
.search-result-insight-name:active {
  color: #5ce1e6;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .search-result-title:visited,
  .search-result-title:link {
    font-size: 2rem;
  }

  .search-result-trending-topics-subhead {
    font-size: 1.6rem;
  }

  .search-result-insight-name:link,
  .search-result-insight-name:visited {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .search-result-title:visited,
  .search-result-title:link {
    font-size: 1.8rem;
  }

  .search-result-trending-topics-subhead {
    font-size: 1.4rem;
  }

  .search-result-insight-name:link,
  .search-result-insight-name:visited {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
}
