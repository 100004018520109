/* ***************************** */
/* MORE ABOUT OUR DATA STYLE */
/* ***************************** */

.section-more-about-data {
  padding: 9.6rem 0 4.8rem;
  color: #004aad;
  background-color: #fff;
}

.more-about-heading-primary {
  text-align: center;
  font-size: 3.6rem;
  font-weight: 700;
  margin-bottom: 8rem;
}

.grid.more-about-data-main-content {
  gap: 14rem;
  margin: 6.4rem 0 9.6rem;
}

.more-about-main-icon {
  justify-self: right;
}

.more-about-data-text {
  font-size: 2.4rem;
  max-width: 34rem;
  justify-self: left;
}

.more-about-heading-secondary {
  text-align: center;
  font-size: 1.8rem;
  margin: 9.6rem 0 4.8rem;
}

.grid.more-about-data-sources-container-1 {
  margin: 0 auto 4.8rem;
}

.grid.more-about-data-sources-container-2 {
  margin: 0 auto 6.4rem;
}

.data-group {
  text-align: center;
  width: 20rem;
  height: 20rem;
  border-radius: 50%;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transition: all 0.4s;

  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}

.data-group:hover {
  color: #004aad;
  /* text-decoration: underline; */
  transform: translateY(-10%);
}

.data-group-icon {
  max-width: 8rem;
  margin-bottom: 1.2rem;
}

.data-group-title {
  font-size: 1.8rem;
  color: #004aad;
}

.data-group-claims {
  grid-column: 2 / 3;
}

.data-group-labeling {
  grid-column: 2 / 3;
}

.back-to-home-page-btn-container {
  display: flex;
  align-items: center;
  justify-content: center;

  margin: 12rem 0;
}

.back-to-home-page-btn:link,
.back-to-home-page-btn:visited {
  display: block;
  padding: 2.4rem 4.8rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  color: #004aad;

  text-decoration: none;
  font-size: 2rem;
  transition: all 0.4s;
}

.back-to-home-page-btn:active,
.back-to-home-page-btn:hover {
  background-color: #004aad;
  color: #fff;
}

.flaticon-attribution {
  text-align: center;
  transition: all 0.3s;
}

.flaticon-attribution-link:link,
.flaticon-attribution-link:visited {
  color: #004aad;
}

.flaticon-attribution-link:hover,
.flaticon-attribution-link:active {
  color: #5ce1e6;
}
