.global-nav-search-bar-form {
  position: relative;
  display: flex;
  gap: 1.2rem;
  margin-left: 3.2rem;

  width: 30%;
}

.global-nav-search-input {
  width: 80%;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  color: #004aad;
  font-size: 1.2rem;
  font-family: inherit;
}

.global-nav-search-btn {
  display: flex;
  align-items: center;

  text-decoration: none;
  font-family: inherit;
  font-size: 1.2rem;
  font-weight: 500;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  cursor: pointer;

  background-color: #004aad;
  color: white;

  transition: all 0.4s;
}

.global-nav-search-btn:hover,
.global-nav-search-btn:active {
  background-color: #fff;
  color: #004aad;
}

.global-nav-search-results-preview {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  min-height: 5rem;
  width: 75%;
  padding: 0.8rem 0.8rem;
  background-color: #fff;
  border: 1px solid #fff;
}

.global-nav-search-result-preview-item:link,
.global-nav-search-result-preview-item:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #004aad;
  transition: all 0.4s;
}

.global-nav-search-result-preview-item:hover,
.global-nav-search-result-preview-item:active {
  color: #5ce1e6;
}

.global-nav-search-results-preview-empty-text {
  font-size: 1.6rem;
}

.global-nav-search-results-preview-position {
  top: 125%;
}

.global-nav-hidden {
  display: none;
}

.global-nav-wait-list-btn-container {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.global-nav-wait-list-btn:visited,
.global-nav-wait-list-btn:link {
  background-color: #004aad;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  text-decoration: none;
  color: #fff;
  font-size: 1.8rem;

  transition: all 0.4s;
}

.global-nav-wait-list-btn:hover,
.global-nav-wait-list-btn:active {
  background-color: #fff;
  color: #004aad;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .global-nav-search-bar-form {
    width: 40%;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .global-nav-search-bar-form {
    width: 45%;
    gap: 0.4rem;
  }

  .global-nav-search-input {
    font-size: 1rem;
  }

  .global-nav-search-btn {
    font-size: 1rem;
    padding: 1.2rem 1.6rem;
  }

  .global-nav-search-result-preview-item:link,
  .global-nav-search-result-preview-item:visited {
    font-size: 1.2rem;
  }

  .global-nav-wait-list-btn:visited,
  .global-nav-wait-list-btn:link {
    font-size: 1.6rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .global-nav-search-btn {
    font-size: 1rem;
    padding: 1.2rem 1.2rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .global-nav-search-input {
    font-size: 0.8rem;
    padding: 0.8rem 1.6rem;
  }

  .global-nav-search-btn {
    font-size: 0.8rem;
    padding: 0.8rem 1.6rem;
  }

  .global-nav-wait-list-btn:visited,
  .global-nav-wait-list-btn:link {
    padding: 1.2rem;
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .global-nav-search-bar-form {
    width: 50%;
  }

  .global-nav-search-input {
    font-size: 0.8rem;
    padding: 0.8rem 1.2rem;
  }

  .global-nav-search-btn {
    font-size: 0.8rem;
    padding: 0.8rem 1rem;
  }

  .global-nav-search-result-preview-item:link,
  .global-nav-search-result-preview-item:visited {
    font-size: 1rem;
  }

  .global-nav-wait-list-btn:visited,
  .global-nav-wait-list-btn:link {
    padding: 0.8rem;
  }
}
