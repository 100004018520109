.search-bar-form {
  position: relative;
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.btn {
  text-decoration: none;
  font-family: inherit;
  font-size: 1.6rem;
  font-weight: 500;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  cursor: pointer;
  transition: all 0.3s;
}

.search-btn {
  border: 1px solid #004aad;
  background-color: #004aad;
  color: white;
}

.search-btn:hover {
  background-color: #fff;
  color: #004aad;
}

.search-btn-link:link,
.search-btn-link:visited {
  text-decoration: none;
}

.search-empty-reminder {
  font-size: 1.4rem;
  color: inherit;
  font-style: italic;
}

.search-results-preview {
  position: absolute;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;

  min-height: 5rem;
  width: 60%;
  padding: 0.8rem 0.8rem;
  background-color: #fff;
  border: 1px solid #fff;
}

.search-results-preview-hidden {
  display: none;
}

/* NOTE: the search resuts preview position styling is in the respective parent components of the search-bar component
(eg, landing-page and search-results-page components)
*/

.search-result-preview-item:link,
.search-result-preview-item:visited {
  text-decoration: none;
  font-size: 1.6rem;
  color: #004aad;
  transition: all 0.4s;
}

.search-result-preview-item:hover,
.search-result-preview-item:active {
  color: #5ce1e6;
}

.search-results-preview-empty-text {
  font-size: 1.6rem;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .btn {
    font-size: 1.2rem;
    padding: 1.2rem 1.6rem;
  }

  .search-result-preview-item:link,
  .search-result-preview-item:visited {
    font-size: 1.4rem;
  }

  .search-results-preview-empty-text {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .search-results-preview {
    width: 50%;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
}
