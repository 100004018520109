.survey-page-section-container {
  padding: 4.8rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.survey-page-title {
  font-size: 3.6rem;
  font-weight: 700;
  color: #004aad;
  margin-bottom: 4.8rem;
}

.survey-page-subhead {
  font-size: 3rem;
  font-weight: 600;
  margin-top: 3.2rem;
}

.survey-page-horizontal-line {
  width: 15%;
  height: 0;
  border: 0.5px solid #555;
  margin-bottom: 1.2rem;
}

.survey-page-survey-questions-container {
  margin: 3.2rem 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .survey-page-title {
    font-size: 3rem;
    margin-bottom: 3.2rem;
  }

  .survey-page-subhead {
    font-size: 2.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .survey-page-horizontal-line {
    width: 25%;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .survey-page-horizontal-line {
    width: 30%;
  }

  .survey-page-title {
    font-size: 2.4rem;
    margin-bottom: 3.2rem;
  }

  .survey-page-subhead {
    font-size: 2rem;
  }
}
