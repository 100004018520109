/* DATA INSIGHT COMPONENT STYLING */
.data-insight-container {
  position: relative;
  padding: 3.2rem 2.4rem;
  height: 50rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;

  border: 1px solid #004aad;
  border-radius: 9px;
  box-shadow: 0 2.4rem 4.8rem rgba(0, 0, 0, 0.075);
  transition: all 0.4s;
}

.data-insight-container:hover {
  transform: translateY(-0.75%);
}

.data-insight-title {
  position: absolute;
  border: 1px solid #555;
  border-radius: 9px;
  padding: 1.6rem 3.2rem;
  max-height: 6.4rem;
  width: 40rem;

  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  top: -2%;
  left: 50%;
  transform: translate(-50%, -50%);

  font-size: 1.8rem;
  font-weight: 500;
  color: #fff;
  background-color: #004aad;
}

.data-insight-badges-and-buttons-container {
  display: flex;
  justify-content: space-between;
  width: 95%;
  margin-top: 1.6rem;
}

.data-insight-badges-container {
  width: 20%;
}

.data-insight-ratings-container {
  display: flex;
  gap: 1.2rem;
}

.data-insight-ratings-btn {
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 0.4rem;

  background-color: transparent;
  border: none;

  cursor: pointer;
  transition: all 0.4s;
}

.clicked-ratings-btn {
  transform: translateY(-30%);
}

.data-insight-ratings-icon {
  width: 1.8rem;
}

.data-insight-ratings-label {
  font-size: 1rem;
  font-weight: 600;
  color: #555;
  transition: all 0.3s;
}

.clicked-label {
  color: red;
}

.data-insight-summary {
  text-align: center;
  font-size: 1.6rem;
  color: #555;
}

.data-insight-summary-top-items {
  font-weight: 600;
  color: #004aad;
}

.data-insight-horizontal-line {
  width: 50%;
  border: 1px solid #555;
}

.data-insight-data-container {
  width: 95%;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.data-insight-data-description {
  font-size: 1.4rem;
  text-align: center;
  color: #555;
}

.data-insight-data-description-respondent-count-highlight {
  margin-left: 0.4rem;
  font-weight: 600;
}

.data-insight-data-visual-container {
  margin-top: 2.4rem;

  position: relative;
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.data-insight-drug-or-disease-link-demo-disclaimer {
  position: absolute;
  bottom: 10%;
  left: 5%;
  width: 50%;
  padding: 0.8rem 1.6rem;

  border: 1px solid #555;
  border-radius: 9px;

  background-color: #fff;
  font-size: 1.2rem;
  color: red;

  transition: all 0.4s;
}

.disclaimer-hidden {
  display: none;

  transition: all 0.4s;
}

.data-insight-data-visual-row-circles {
  display: flex;
  align-items: center;
  gap: 1.2rem;
}

.data-insight-data-label-circles:link,
.data-insight-data-label-circles:visited {
  width: 20rem;

  font-size: 1.2rem;
  text-decoration: none;
  color: #004aad;

  transition: all 0.4s;
}

.data-insight-data-label-circles:hover,
.data-insight-data-label-circles:active {
  color: #5ce1e6;
}

.data-insight-data-visualization-circles {
  display: flex;
  gap: 0.1rem;
}

.data-visualization-circle-full {
  height: 1.8rem;
  width: 1.8rem;
  border: 1px solid #004aad;
  background-color: #004aad;
  border-radius: 50%;
}

.data-visualization-circle-empty {
  height: 1.8rem;
  width: 1.8rem;
  border: 1px solid #004aad;
  background-color: #fff;
  border-radius: 50%;
}

.data-insight-data-visualization-number-label {
  display: flex;
  width: 5rem;
  align-items: center;
  color: #555;
  font-size: 1.2rem;

  transition: all 0.3s;
}

.data-insight-data-number {
  align-self: center;
  justify-self: center;

  font-size: 4.4rem;
  font-weight: 600;
  color: #004aad;
}

.data-insight-more-details-container {
  position: absolute;
  bottom: 5%;
  right: 5%;
  display: flex;
  justify-content: flex-end;
  width: 95%;
}

.data-insight-more-details-btn {
  padding: 0.8rem 2.4rem;
  color: #555;
  background-color: #fff;
  border: 1px solid #004aad;
  border-radius: 9px;
  font-size: 1.4rem;

  cursor: pointer;
  transition: all 0.4s;
}

.data-insight-more-details-btn:hover,
.data-insight-more-details-btn:active {
  background-color: #004aad;
  color: #fff;
}

/* .data-insight-data-items-bar-chart-container {
  display: flex;
  justify-content: space-around;
  gap: 1.2rem;
  height: 25rem;
}

.data-bar-container {
  display: flex;
  flex-direction: column;
  justify-content: flex-end;
  align-items: center;
  gap: 0.8rem;
}

.data-item-bar-container {
  height: 100%;
  display: flex;
  align-items: flex-end;
}

.data-item-bar {
  position: relative;
  margin: 0 auto;
  height: 1%;
  width: 4rem;
  background-color: #004aad;
  transition: all 0.8s;
}

.data-item-bar-complete {
  margin: 0 auto;
  height: var(--bar-value);
  width: 4rem;
  background-color: #004aad;
}

.data-item-bar-label {
  position: absolute;
  top: 20%;
  left: 50%;
  transform: translateX(-50%);
  font-size: 1.4rem;
  font-weight: 600;
  color: #fff;
}

.data-item-title:link,
.data-item-title:visited {
  text-align: center;
  min-height: 5rem;
  width: 8rem;

  text-decoration: none;
  color: inherit;
  font-size: 1.2rem;
  font-weight: 500;

  transition: all 0.4s;
}

.data-item-title:hover,
.data-item-title:active {
  color: #5ce1e6;
} */

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  .data-insight-container {
    min-height: 43rem;
  }

  .data-visualization-circle-full {
    height: 1.6rem;
    width: 1.6rem;
  }

  .data-visualization-circle-empty {
    height: 1.6rem;
    width: 1.6rem;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .data-insight-container {
    min-height: 40rem;
  }

  .data-insight-title {
    top: 0;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .data-insight-container {
    width: 60rem;
  }

  .data-insight-title {
    font-size: 1.6rem;
  }

  .data-insight-summary {
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .data-insight-container {
    max-width: 55rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .data-insight-container {
    max-width: 42rem;
    max-height: 50rem;
  }

  .data-insight-ratings-icon {
    width: 1.6rem;
  }

  .data-insight-ratings-label {
    font-size: 0.8rem;
  }

  .data-insight-title {
    max-height: 4.8rem;
    max-width: 30rem;
    font-size: 1.4rem;
  }

  .data-insight-summary {
    font-size: 1.2rem;
  }

  .data-insight-data-description {
    font-size: 1.2rem;
  }

  .data-insight-data-label-circles:link,
  .data-insight-data-label-circles:visited {
    font-size: 1rem;
    width: 16rem;
  }

  .data-visualization-circle-full {
    height: 1.4rem;
    width: 1.4rem;
  }

  .data-visualization-circle-empty {
    height: 1.4rem;
    width: 1.4rem;
  }

  .data-insight-data-number {
    font-size: 3.6rem;
  }

  .data-insight-more-details-btn {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .data-insight-container {
    max-width: 38rem;
    min-height: 48rem;
    padding: 1.6rem 1.2rem;
    gap: 1.6rem;
  }

  .data-insight-ratings-icon {
    width: 1.2rem;
  }

  .data-insight-ratings-label {
    font-size: 0.8rem;
  }

  .data-insight-title {
    max-height: 4rem;
    max-width: 25rem;
    font-size: 1.2rem;
  }

  .data-insight-summary {
    max-width: 95%;
  }

  .data-insight-data-description {
    font-size: 1rem;
  }

  .data-insight-data-visual-container {
    width: 35rem;
  }

  .data-insight-data-visual-row-circles {
    gap: 3.2rem;
  }

  .data-insight-data-label-circles:link,
  .data-insight-data-label-circles:visited {
    font-size: 1rem;
    width: 10rem;
  }

  .data-visualization-circle-full {
    height: 1.2rem;
    width: 1.2rem;
  }

  .data-visualization-circle-empty {
    height: 1.2rem;
    width: 1.2rem;
  }

  .data-insight-data-visualization-number-label {
    width: 4rem;
    font-size: 1rem;
  }

  .data-insight-more-details-btn {
    font-size: 1rem;
  }
}
