.insights-filter-modal-container {
  position: fixed;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 105;

  min-width: 60%;
  min-height: 54%;
  padding: 1.2rem 2.4rem;

  background-color: #fff;
  border: 1px solid #5ce1e6;
  border-radius: 9px;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  transition: all 0.4s;
}

.insights-filter-modal-close-btn {
  align-self: flex-end;
  margin: 0.8rem 0.8rem 0 0;

  background-color: transparent;
  border: none;
  cursor: pointer;
}

.insights-filter-modal-close-btn-icon {
  width: 1.6rem;
}

.insights-filter-modal-header {
  font-size: 2.4rem;
  font-weight: 500;
  text-align: center;
}

.insights-filter-modal-subhead {
  font-size: 1.4rem;
  font-style: italic;
  margin-bottom: 1.6rem;
  text-align: center;
}

.insights-filter-modal-header-page-name {
  color: #004aad;
  font-weight: 700;
}

.insights-filter-modal-all-filters-container {
  width: 100%;
  padding: 1.2rem 2.4rem;
  column-gap: 4.8rem;
}

.insights-filter-modal-filter-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.insights-filter-modal-filter-name {
  color: #555;
  font-size: 2rem;
  font-weight: 600;
  margin-bottom: 0.4rem;
}

.insights-filter-drug-or-disease-select {
  max-width: 30rem;
  border: 1px solid #5ce1e6;
  border-radius: 9px;
  padding: 0.4rem 0.8rem;

  font-size: 1.4rem;
}

.insights-filter-clinicican-type-checkboxes-container {
  display: flex;
  flex-direction: column;
  gap: 0.8rem;
}

.insights-filter-clinician-type-checkbox-row {
  display: flex;
  align-items: center;
  gap: 0.4rem;
}

.insights-filter-clinician-type-label {
  color: #555;
  font-size: 1.8rem;
}

.insights-filter-modal-clinican-types-filter-warning {
  margin-top: 3.2rem;
  font-size: 1.6rem;
  text-align: center;
  color: maroon;
  transition: all 0.4s;
}

.modal-filter-warning-transparent {
  color: transparent;
}

.insights-filter-submit-btn {
  background-color: maroon;
  color: #fff;
  border: 1px solid maroon;
  border-radius: 9px;
  padding: 0.8rem 1.6rem;
  margin: 1.2rem 0;
  cursor: pointer;
  font-size: 1.6rem;

  transition: all 0.4s;
}

.insights-filter-submit-btn:hover,
.insights-filter-submit-btn:active {
  background-color: #004aad;
  color: #fff;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  .insights-filter-modal-container {
    min-height: 47%;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .insights-filter-modal-container {
    min-width: 60%;
    min-height: 42%;
  }

  .insights-filter-modal-all-filters-container {
    column-gap: 2.4rem;
  }

  .insights-filter-modal-header {
    font-size: 2rem;
  }

  .insights-filter-modal-subhead {
    font-size: 1.2rem;
  }

  .insights-filter-modal-filter-name {
    font-size: 1.8rem;
  }

  .insights-filter-drug-or-disease-select {
    font-size: 1.2rem;
    max-width: 25rem;
  }

  .insights-filter-clinician-type-checkbox-row {
    gap: 0.8rem;
  }

  .insights-filter-clinician-type-label {
    font-size: 1.4rem;
  }

  .insights-filter-modal-clinican-types-filter-warning {
    margin-top: 2.4rem;
    font-size: 1.4rem;
  }

  .insights-filter-submit-btn {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .insights-filter-modal-container {
    min-width: 70%;
    padding: 1.2rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .insights-filter-modal-container {
    min-width: 75%;
    min-height: 40%;
  }

  .insights-filter-modal-all-filters-container {
    padding: 1.2rem;
  }

  .insights-filter-modal-header {
    font-size: 1.8rem;
  }

  .insights-filter-modal-subhead {
    font-size: 1rem;
  }

  .insights-filter-modal-filter-name {
    font-size: 1.6rem;
  }

  .insights-filter-drug-or-disease-select {
    font-size: 1rem;
    max-width: 15rem;
  }

  .insights-filter-clinician-type-label {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .insights-filter-modal-container {
    min-width: 78%;
    min-height: 34%;
  }

  .insights-filter-modal-close-btn {
    margin: 0.4rem 0.4rem 0 0;
  }

  .insights-filter-modal-close-btn-icon {
    width: 1.4rem;
  }

  .insights-filter-modal-all-filters-container {
    column-gap: 1.6rem;
    padding: 1.2rem;
  }

  .insights-filter-modal-header {
    font-size: 1.6rem;
  }

  .insights-filter-modal-filter-name {
    font-size: 1.4rem;
  }

  .insights-filter-clinician-type-label {
    font-size: 1rem;
  }

  .insights-filter-modal-clinican-types-filter-warning {
    font-size: 1.2rem;
  }

  .insights-filter-submit-btn {
    font-size: 1rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .insights-filter-modal-container {
    min-width: 80%;
    min-height: 32%;
  }

  .insights-filter-modal-close-btn-icon {
    width: 1.2rem;
  }

  .insights-filter-modal-header {
    font-size: 1.4rem;
  }

  .insights-filter-modal-subhead {
    font-size: 0.8rem;
  }

  .insights-filter-modal-all-filters-container {
    padding: 0;
  }

  .insights-filter-modal-filter-name {
    font-size: 1.2rem;
  }

  .insights-filter-clinician-type-label {
    font-size: 0.8rem;
  }
}
