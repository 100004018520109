.survey-question-container {
  position: relative;
  width: 90%;
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.6rem;

  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 2.4rem 4.8rem;
}

.survey-question-topic {
  position: absolute;
  top: -7.5%;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  align-items: center;
  justify-content: center;

  width: 20%;
  background-color: #fff;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  color: #004aad;
  font-size: 1.8rem;
  font-weight: 500;
}

.survey-question-blur {
  position: absolute;
  bottom: 0;
  left: 0;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 2.4rem;

  width: 100%;
  height: 100%;
  background-color: rgba(255, 255, 255, 0.9);
  z-index: 10;
  border-radius: 9px;
}

.survey-blur-hidden {
  display: none;
}

.survey-question-blur-subhead {
  font-size: 2rem;
  font-weight: 500;
  color: #004aad;
}

.survey-question-blur-change-answer-btn {
  background-color: #004aad;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  color: #fff;
  font-size: 1.6rem;

  cursor: pointer;
  transition: all 0.4s;
}

.survey-question-blur-change-answer-btn:hover,
.survey-question-blur-change-answer-btn:active {
  background-color: #fff;
  color: #004aad;
}

.survey-question-survey-number {
  font-size: 2.4rem;
  font-weight: 600;
  color: #555;
}

.survey-question-question-text {
  margin-top: 3.2rem;
  font-size: 2.4rem;
  font-weight: 600;
  color: #004aad;
}

.survey-question-answer-container {
  margin-top: 2.4rem;
}

/* NUMBER ANSWER INPUT */
.survey-question-number-answer {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;

  width: 25rem;
}

.survey-question-number-input {
  width: 100%;
  padding: 1.2rem 1.2rem;
  font-size: 1.8rem;
  border-radius: 9px;
  border: 1px solid #555;
}

.survey-question-number-input::placeholder {
  font-size: 1.6rem;
}

.survey-question-multiple-choice-answers-container {
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.survey-question-multiple-choice-answer-btn {
  background-color: #fff;
  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;

  color: #004aad;
  font-weight: 400;
  font-size: 1.8rem;

  cursor: pointer;
  transition: all 0.4s;
}

.survey-question-multiple-choice-answer-btn:hover,
.survey-question-multiple-choice-answer-btn:active {
  background-color: #004aad;
  color: #fff;
}

.multiple-choice-answer-pressed {
  background-color: #004aad;
  color: #fff;
  border: 1px solid #004aad;
}

.submit-answer-btn {
  margin-top: 4.8rem;
  width: 20rem;

  padding: 1.2rem;
  background-color: maroon;
  border: 1px solid maroon;
  border-radius: 9px;

  font-size: 1.6rem;
  font-weight: 500;
  color: #fff;

  cursor: pointer;
  transition: all 0.4s;
}

.submit-answer-btn:hover,
.submit-answer-btn:active {
  background-color: #fff;
  color: maroon;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .survey-question-topic {
    width: 30%;
    font-size: 1.6rem;
  }

  .survey-question-survey-number {
    font-size: 2rem;
  }

  .survey-question-question-text {
    margin-top: 2.4rem;
    font-size: 2rem;
  }

  .survey-question-number-answer {
    width: 20rem;
  }

  .survey-question-number-input {
    font-size: 1.6rem;
  }

  .survey-question-number-input::placeholder {
    font-size: 1.4rem;
  }

  .survey-question-multiple-choice-answer-btn {
    font-size: 1.6rem;
  }

  .submit-answer-btn {
    width: 15rem;
    font-size: 1.4rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .survey-question-topic {
    top: -6%;
    width: 35%;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .survey-question-container {
    gap: 1.2rem;
    padding: 2.4rem;
  }

  .survey-question-number-answer {
    width: 15rem;
  }

  .survey-question-answer-container {
    width: 100%;
  }

  .survey-question-number-input {
    width: 16rem;
  }

  .submit-answer-btn {
    margin-top: 2.4rem;
    width: 13rem;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .survey-question-topic {
    top: -5%;
    width: 40%;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .survey-question-blur-subhead {
    font-size: 1.8rem;
  }

  .survey-question-blur-change-answer-btn {
    font-size: 1.4rem;
  }

  .survey-question-topic {
    top: -4.5%;
    width: 40%;
    font-size: 1.4rem;
  }

  .survey-question-survey-number {
    font-size: 1.8rem;
  }

  .survey-question-question-text {
    margin-top: 2.4rem;
    font-size: 1.8rem;
  }

  .survey-question-number-answer {
    width: 10rem;
  }

  .survey-question-number-input {
    font-size: 1.4rem;
  }

  .survey-question-number-input::placeholder {
    font-size: 1.2rem;
  }

  .survey-question-multiple-choice-answer-btn {
    font-size: 1.4rem;
  }

  .submit-answer-btn {
    width: 12rem;
    font-size: 1.2rem;
  }
}
