.landing-page-section-container {
  position: relative;

  display: flex;
  flex-direction: column;
  align-items: center;

  max-width: 100vw;
  margin: 0 auto;
}

.landing-page-blur {
  position: fixed;
  top: -10%;
  right: 0;

  height: 150vh;
  width: 100vw;

  background-color: rgba(222, 249, 250, 0.9);

  z-index: 50;
}

.landing-page-blur-off {
  display: none;
}

.scroll-anchor {
  position: absolute;
  top: -130px;
  z-index: -100;
}

/* ********************* */
/* HEADER AND NAV SECTION */
/* ********************* */

.landing-page-header {
  position: absolute;
  top: 0;
  width: 100vw;
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: space-between;

  background-color: #fff;

  padding: 1.2rem 2.4rem;
}

.landing-page-header-fixed {
  position: fixed;
}

.landing-page-header-orca-logo {
  max-height: 8rem;
}

.landing-page-nav {
  transform: translateY(25%);

  display: flex;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
}

.landing-page-nav-close-btn {
  display: none;
  background: transparent;
  border: none;
}

.landing-page-nav-close-btn-icon {
  max-width: 3rem;
}

.landing-page-nav-btn {
  background-color: transparent;
  border: none;

  font-size: 1.8rem;
  font-weight: 500;
  color: #004aad;

  cursor: pointer;
  transition: all 0.4s;
}

.landing-page-nav-btn:active,
.landing-page-nav-btn:hover {
  color: #5ce1e6;
}

.demo-btn {
  font-weight: 600;
}

.landing-page-nav-wait-list-cta-btn {
  background-color: #004aad;

  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  color: #fff;
  font-size: 1.8rem;
  font-weight: 600;

  cursor: pointer;
  transition: all 0.4s;
}

.landing-page-nav-wait-list-cta-btn:active,
.landing-page-nav-wait-list-cta-btn:hover {
  background-color: #fff;
  color: #004aad;
}

.landing-page-hidden-nav-btn {
  display: none;
  background-color: transparent;
  border: none;
}

.landing-page-hidden-nav-btn-icon {
  max-width: 5.2rem;
}

/* ********************* */
/* MAIN CONTENT AND HERO SECTION */
/* ********************* */

.landing-page-main-content-container {
  margin: 11rem 0 3.2rem;

  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 9.6rem;
}

.landing-page-hero-container {
  min-height: 75vh;
  display: flex;
  align-items: center;
  justify-content: center;

  padding: 0 3.2rem;
}

.landing-page-hero-lockup {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: center;
  justify-items: center;

  padding: 0 4.8rem;
}

.landing-page-hero-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;

  padding: 1.2rem 0 1.2rem 1.2rem;
}

.landing-page-hero-title {
  color: #004aad;
  font-size: 4.4rem;
  font-weight: 700;
}

.landing-page-hero-description {
  color: #555;
  font-size: 2.4rem;
  font-weight: 500;
}

.landing-page-hero-description-bold {
  font-weight: 500;
  color: #336ebd;
}

.landing-page-hero-demo-btn:link,
.landing-page-hero-demo-btn:visited {
  margin-top: 1.6rem;

  padding: 1.2rem 2.4rem;
  border: 1px solid #004aad;
  border-radius: 9px;
  background-color: #004aad;

  font-size: 2rem;
  font-weight: 500;
  text-decoration: none;
  color: #fff;

  transition: all 0.4s;
}

.landing-page-hero-demo-btn:active,
.landing-page-hero-demo-btn:hover {
  background-color: #fff;
  color: #004aad;
}

.landing-page-hero-img {
  max-width: 58rem;
}

/* ********************* */
/* WHAT IS IT? SECTION */
/* ********************* */

.landing-page-what-container {
  position: relative;
  /* shift the y position of this container so that
  it appears immediately after scrolling past the hero section of
  landing page */
  transform: translateY(-2.4rem);

  background-color: #def9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 8rem;

  margin-top: 9.6rem;
  padding: 9.6rem 0;
}

.landing-page-subhead-lockup {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 2.4rem;
}

.landing-page-subhead {
  position: relative;
  font-size: 3.6rem;
  font-weight: 700;
  color: #004aad;
}

.landing-page-horizontal-line {
  width: 80%;
  height: 0;
  border: 1px solid #004aad;
}

.landing-page-what-cards-container {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  align-items: flex-start;
  padding: 0 8rem;

  column-gap: 3.2rem;
}

.landing-page-what-card {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;

  padding: 1.2rem 0;
}

.landing-page-what-card-img-figure {
  background-color: #fff;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;
}

.landing-page-what-card-img {
  max-height: 20rem;
}

.landing-page-what-card-text-box {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 1.2rem;

  padding: 0 8rem 0 0;
}

.landing-page-what-card-title {
  color: #004aad;
  font-size: 2.4rem;
  font-weight: 600;
}

.landing-page-what-card-description {
  font-size: 2rem;
  font-weight: 400;
}

/* ********************* */
/* OUR DATA SECTION */
/* ********************* */

.landing-page-data-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;
}

.our-data-subhead {
  color: #336ebd;
}

.landing-page-data-content-cards-container {
  display: flex;
  flex-direction: column;
  align-items: flex-start;
  gap: 2.4rem;

  max-width: 65%;
}

.landing-page-data-content-card {
  display: flex;
  align-items: center;
  gap: 6.4rem;
}

.landing-page-data-content-card-img {
  max-width: 40rem;
}

.landing-page-data-content-card-text-box {
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 2.4rem;

  min-height: 23rem;
  padding: 2.4rem 3.2rem;
  border: 1px solid #336ebd;
  border-radius: 9px;
}

.landing-page-data-content-card-title {
  font-size: 2.4rem;
  font-weight: 600;
  color: #336ebd;
}

.landing-page-data-content-card-list {
  list-style: none;
  display: flex;
  flex-direction: column;
  gap: 1.2rem;
}

.landing-page-data-content-card-list-item {
  display: flex;
  align-items: flex-start;
  gap: 0.4rem;
}

.landing-page-data-content-card-list-item-bullet {
  padding-top: 0.4rem;
}

.landing-page-data-content-card-list-item-bullet-icon {
  max-width: 1.2rem;
}

.landing-page-data-content-card-list-item-text {
  font-size: 1.8rem;
  color: #555;
}

.landing-page-data-examples-container {
  margin-top: 8rem;
  align-self: stretch;

  display: flex;
  flex-direction: column;
  align-items: center;

  padding: 0 8rem;

  gap: 4.8rem;
}

.landing-page-data-examples-subhead {
  font-size: 2.4rem;
}

.landing-page-data-examples-pictures {
  display: grid;
  grid-template-columns: repeat(2, 1fr);
  column-gap: 2.4rem;
  row-gap: 2.4rem;
  align-items: stretch;
  justify-items: center;
}

.landing-page-data-example-img-figure {
  padding: 1.2rem 2.4rem;
  border: 1px solid #336ebd;
  border-radius: 9px;
  background-color: #fff;
}

.data-example-img-no-border {
  border: none;
}

.landing-page-data-example-img {
  max-width: 70rem;
}

/* ********************* */
/* DEMO SECTION */
/* ********************* */
.landing-page-demo-container {
  position: relative;
  background-color: #def9fa;
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 4.8rem;

  margin-top: 4.8rem;
  padding: 9.6rem 8rem;
}

.landing-page-demo-intro {
  max-width: 60%;
  font-size: 2rem;
  font-weight: 500;
  text-align: center;
}

.landing-page-demo-intro-emphasis {
  font-weight: 600;
  color: #004aad;
}

.landing-page-demo-link:visited,
.landing-page-demo-link:link {
  background-color: #004aad;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 2.4rem 4.8rem;

  font-size: 2.4rem;
  font-weight: 700;
  text-decoration: none;

  color: #fff;
  transition: all 0.4s;
}

.landing-page-demo-link:hover,
.landing-page-demo-link:active {
  background-color: #fff;
  color: #004aad;
}

.landing-page-demo-disclaimer {
  color: #555;
  font-size: 1.6rem;
  font-weight: 400;
  font-style: italic;
}

.landing-page-demo-disclaimer-emphasis {
  font-weight: 600;
}

/* ********************* */
/* WAIT LIST SECTION */
/* ********************* */

.landing-page-wait-list-container {
  position: relative;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  gap: 4.8rem;
}

.landing-page-wait-list-intro {
  font-size: 2rem;
  color: #336ebd;
  margin-bottom: 2.4rem;
}

.landing-page-wait-list-form {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.6rem;

  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 4.8rem;
}

.landing-page-wait-list-form-item {
  width: 50%;
  display: flex;
  flex-direction: column;
  gap: 0.4rem;
}

.wait-list-checkbox-item {
  flex-direction: row;
  gap: 0.8rem;
}

.wait-list-form-item-hidden {
  display: none;
}

.landing-page-wait-list-form-item-label {
  color: #555;
  font-size: 1.6rem;
  font-weight: 500;
}

.landing-page-wait-list-form-item-input {
  font-size: 1.6rem;
}

.landing-page-waitlist-btns-box {
  margin-top: 2.4rem;
  display: flex;
  align-items: stretch;
  justify-content: center;
  gap: 1.2rem;
}

.landing-page-wait-list-form-submit-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: #004aad;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  color: #fff;
  font-size: 1.8rem;
  font-weight: 500;

  cursor: pointer;
  transition: all 0.4s;
}

.landing-page-wait-list-form-submit-btn:hover,
.landing-page-wait-list-form-submit-btn:active {
  background-color: #fff;
  color: #004aad;
}

.landing-page-wait-list-form-demo-btn:link,
.landing-page-wait-list-form-demo-btn:visited {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;

  background-color: #fff;
  border: 1px solid #004aad;
  border-radius: 9px;
  padding: 1.2rem 2.4rem;

  color: #004aad;
  font-size: 1.8rem;
  font-weight: 500;
  text-decoration: none;

  cursor: pointer;
  transition: all 0.4s;
}

.landing-page-wait-list-form-demo-btn:active,
.landing-page-wait-list-form-demo-btn:hover {
  color: #5ce1e6;
  border: 1px solid #5ce1e6;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1632px (smaller desktops) */
/* *************************** */

@media (max-width: 102em) {
  .landing-page-data-example-img {
    max-width: 60rem;
  }
}

/* *************************** */
/* BELOW 1408px (smaller desktops) */
/* *************************** */

@media (max-width: 88em) {
  .landing-page-data-content-cards-container {
    max-width: 75%;
  }

  .landing-page-data-example-img {
    max-width: 50rem;
  }
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
  .landing-page-hero-container {
    min-height: 82vh;
  }

  .landing-page-hero-img {
    max-width: 52rem;
  }

  .landing-page-what-cards-container {
    column-gap: 1.2rem;
    padding: 0 9.6rem;
  }

  .landing-page-what-card-img {
    max-height: 18rem;
  }

  .landing-page-what-card-text-box {
    padding: 0 4.8rem 0 0;
  }

  .landing-page-data-content-cards-container {
    max-width: 82%;
  }

  .landing-page-data-content-card-img {
    max-width: 30rem;
  }

  .landing-page-data-content-card-title {
    font-size: 2rem;
  }

  .landing-page-data-content-card-list-item-text {
    font-size: 1.6rem;
  }

  .landing-page-data-example-img {
    max-width: 44rem;
  }

  .landing-page-demo-intro {
    max-width: 70%;
  }

  .landing-page-demo-disclaimer {
    max-width: 70%;
  }
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .landing-page-nav {
    gap: 3.2rem;
  }

  .landing-page-main-content-container {
    gap: 6.4rem;
  }

  .landing-page-hero-text-box {
    gap: 1.6rem;
  }

  .landing-page-hero-title {
    font-size: 3.6rem;
  }

  .landing-page-hero-description {
    font-size: 2rem;
  }

  .landing-page-hero-demo-btn:link,
  .landing-page-hero-demo-btn:visited {
    margin-top: 1.2rem;
  }

  .landing-page-hero-img {
    max-width: 36rem;
  }

  .landing-page-what-card-img {
    max-height: 10rem;
  }

  .landing-page-what-card-title {
    font-size: 2rem;
  }

  .landing-page-what-card-description {
    font-size: 1.8rem;
  }

  .landing-page-data-example-img {
    max-width: 33rem;
  }

  .landing-page-demo-intro {
    max-width: 80%;
  }

  .landing-page-demo-disclaimer {
    max-width: 80%;
  }

  .landing-page-wait-list-form {
    max-width: 90%;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
  .landing-page-nav {
    position: absolute;
    right: -100%;
    top: -120%;

    background-color: #fff;
    padding: 4.8rem;
    width: 70vw;

    border: 1px solid #004aad;
    border-radius: 9px;

    flex-direction: column;
    gap: 8rem;

    transition: all 0.4s;
  }

  .landing-page-nav-close-btn {
    display: block;
    position: absolute;
    top: 5%;
    left: 5%;
  }

  .landing-page-hidden-nav-btn {
    display: block;
  }

  .landing-page-hidden-nav-open {
    right: 0;
    z-index: 1000;
  }

  .landing-page-nav-btn {
    font-size: 3.2rem;
  }

  .landing-page-nav-wait-list-cta-btn {
    font-size: 3.2rem;
  }

  .landing-page-hero-lockup {
    display: flex;
    flex-direction: column-reverse;
    align-items: center;
    justify-items: center;
    gap: 2.4rem;

    padding: 0 4.8rem;
  }

  .landing-page-hero-text-box {
    gap: 2.4rem;
    padding: 1.2rem;
  }

  .landing-page-hero-title {
    font-size: 5.2rem;
  }

  .landing-page-hero-description {
    font-size: 3rem;
  }

  .landing-page-hero-img {
    max-width: 45rem;
  }

  .landing-page-what-cards-container {
    display: flex;
    flex-direction: column;
    align-items: center;
    justify-content: center;
    gap: 3.2rem;

    padding: 0 9.6rem;
  }

  .landing-page-what-card {
    align-items: center;
  }

  .landing-page-what-card-text-box {
    align-items: center;
    padding: 0;
  }

  .landing-page-what-card-img {
    max-height: 20rem;
  }

  .what-card-digital-platform-img {
    max-height: 25rem;
  }

  .landing-page-what-card-title {
    font-size: 2.4rem;
  }

  .landing-page-what-card-description {
    font-size: 2rem;
  }

  .landing-page-data-content-card-img {
    max-width: 25rem;
  }

  .landing-page-data-examples-pictures {
    display: flex;
    flex-direction: column;
    gap: 2.4rem;
  }

  .landing-page-data-example-img {
    max-width: 40rem;
  }
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .landing-page-hero-title {
    font-size: 4.4rem;
  }

  .landing-page-hero-description {
    font-size: 2.4rem;
  }

  .landing-page-what-card-img {
    max-height: 16rem;
  }

  .landing-page-data-content-cards-container {
    align-items: center;
    max-width: 70%;
    gap: 4.8rem;
  }

  .landing-page-data-content-card {
    flex-direction: column;
    gap: 3.2rem;
  }

  .landing-page-data-content-card-img {
    max-width: 35rem;
  }

  .landing-page-data-content-card-text-box {
    gap: 1.6rem;

    min-height: 16rem;
    padding: 2.4rem 3.2rem;
    border: 1px solid #336ebd;
    border-radius: 9px;
  }

  .landing-page-demo-disclaimer {
    font-size: 1.4rem;
  }

  .landing-page-wait-list-form {
    max-width: 70%;
  }

  .landing-page-wait-list-form-item {
    width: 80%;
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .landing-page-nav {
    top: -140%;
    padding: 8rem 4.8rem 4.8rem;
  }

  .landing-page-nav-close-btn-icon {
    max-width: 2.4rem;
  }

  .landing-page-nav-btn {
    font-size: 2.4rem;
  }

  .landing-page-nav-wait-list-cta-btn {
    font-size: 2.4rem;
  }

  .landing-page-hero-lockup {
    max-width: 95%;
  }

  .landing-page-hero-img {
    max-width: 35rem;
  }

  .landing-page-hero-title {
    font-size: 3.6rem;
  }

  .landing-page-subhead {
    font-size: 3rem;
  }

  .landing-page-what-card {
    max-width: 90%;
  }

  .landing-page-what-card-img {
    max-height: 12rem;
  }

  .what-card-digital-platform-img {
    max-height: 22rem;
  }

  .landing-page-data-content-cards-container {
    max-width: 70%;
    gap: 6.4rem;
  }

  .landing-page-data-example-img {
    max-width: 30rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
  .landing-page-header-orca-logo {
    max-height: 6.4rem;
  }

  .landing-page-hidden-nav-btn-icon {
    max-width: 4.4rem;
  }

  .landing-page-main-content-container {
    margin: 8rem 0 3.2rem;
    gap: 4.8rem;
  }

  .landing-page-hero-title {
    font-size: 3rem;
  }

  .landing-page-hero-description {
    font-size: 2rem;
  }

  .landing-page-what-card-img {
    max-height: 10rem;
  }

  .what-card-digital-platform-img {
    max-height: 20rem;
  }

  .landing-page-data-content-card-img {
    max-width: 32rem;
  }

  .landing-page-demo-intro {
    font-size: 1.8rem;
  }

  .landing-page-demo-link:visited,
  .landing-page-demo-link:link {
    font-size: 2rem;
  }

  .landing-page-wait-list-form-item {
    width: 100%;
  }
}
