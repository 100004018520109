.patient-builder-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  gap: 1.2rem;

  margin: 2.4rem 0;
}

.patient-builder-header {
  font-size: 2rem;
  font-weight: 700;
  color: #004aad;
}

.patient-builder-description {
  font-size: 1.4rem;
  font-style: italic;
  text-align: center;
}

.patient-builder-filter-buttons-container {
  margin: 1.2rem 0 2.4rem;
  align-items: center;
  column-gap: 2.4rem;
  row-gap: 1.2rem;
}

.patient-builder-btn {
  position: relative;
  width: 30rem;
  padding: 1.2rem 2.4rem;

  background-color: transparent;
  border: 1px solid #004aad;
  border-radius: 9px;
  box-shadow: 0 1.2rem 2.4rem rgba(0, 0, 0, 0.075);

  color: #555;
  font-size: 1.4rem;
  font-weight: 500;

  cursor: pointer;
  transition: all 0.4s;
}

.patient-builder-btn:hover,
.patient-builder-btn:active {
  box-shadow: 0;
  border: 1px solid #5ce1e6;
}

.patient-builder-btn-pressed {
  color: #fff;
  background-color: #004aad;
}

.patient-builder-btn-active-icon {
  position: absolute;
  top: 50%;
  transform: translateY(-50%);
  right: 5%;
}

.patient-builder-treatment-experienced-btn {
  grid-column: 1/2;
}

.patient-builder-decompensated-cirrhosis-btn {
  grid-row: 1/2;
  grid-column: 2/ -1;
}

.patient-builder-injection-drug-use-btn {
  grid-column: 2/-1;
}

.patient-builder-show-more-attributes-btn {
  display: flex;
  align-items: center;
  justify-content: center;
  color: maroon;
  transition: all 0.4s;
}

.patient-builder-show-more-attributes-btn:active,
.patient-builder-show-more-attributes-btn:hover {
  color: #004aad;
}

/* *************************** */
/* *****MEDIA QUERIES********* */
/* *************************** */

/* *************************** */
/* BELOW 1344px (smaller desktops) */
/* *************************** */

@media (max-width: 84em) {
}

/* *************************** */
/* BELOW 1200px (landscape tablets) */
/* *************************** */
@media (max-width: 75em) {
}

/* *************************** */
/* BELOW 944px (tablets) */
/* *************************** */
@media (max-width: 59em) {
  .patient-builder-header {
    font-size: 1.8rem;
  }

  .patient-builder-description {
    font-size: 1.2rem;
  }
}

/* *************************** */
/* BELOW 752px (smaller tablets) */
/* *************************** */
@media (max-width: 47em) {
}

/* *************************** */
/* BELOW 640px (phones) */
/* *************************** */
@media (max-width: 40em) {
  .patient-builder-btn {
    width: 20rem;
    height: 7rem;
  }

  .patient-builder-btn-active-icon {
    width: 3rem;
    top: -5%;
    right: -3%;
    transform: translateY(0%);
  }
}

/* *************************** */
/* BELOW 512px (phones) */
/* *************************** */
@media (max-width: 32em) {
  .patient-builder-header {
    font-size: 1.6rem;
  }

  .patient-builder-description {
    font-size: 1rem;
  }

  .patient-builder-filter-buttons-container {
    margin: 1.2rem 0;
  }

  .patient-builder-btn {
    width: 15rem;
    height: 6rem;
    font-size: 1.2rem;
  }

  .patient-builder-btn-active-icon {
    width: 2.4rem;
  }
}

/* *************************** */
/* BELOW 400px (phones) */
/* *************************** */
@media (max-width: 25em) {
}
